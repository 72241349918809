@import '../semantic-ui/site/globals/site.variables';

.sub-menu-container {
  display: block;
  top: 100px;
  text-align: left;  
}

.ui.vertical.menu {
  background-color: transparent;
  border: none;
  box-shadow: none;
}

.ui.icon.menu .item {
  background-color: transparent;
  color: @dinoblue;
  text-align: left;
}

.ui.menu .submenu {
    font-weight: 600;
    display: block;
}

.ui.menu .item {
    font-weight: 600;
}

.ui.secondary.menu .item.custom-menu-item:hover {
    color: @dinoblue;
}

.ui.secondary.menu .item {
    color: #585858;
}

.ui.secondary.menu .active.item {
    border-color: @dinoblue !important;
    border-width: 2px;
    color: @dinoblue;
    font-weight: 2000;
}

.ui.secondary.menu .active.item :hover {
    border-color: @dinoblue !important;
    border-width: 2px;
    color: @dinoblue !important;
}

.ui.small.image.custom-size-logo {
    height: 31px;
    width: auto;
}

.ui.secondary.menu .item {
    padding-top: 30px;
    padding-bottom: 30px;
}

.ui.secondary.menu .left.item {
    padding-top: 21px;
    padding-bottom: 23px;
    padding-left: 35px;
}

.ui.menu a.item#join-live-button:hover {
    background-color: darken(@dinoblue, 5%);
}

.ui.menu a.item#talk-to-an-expert:hover {
    background-color: darken(@grey, 5%);
}


@media only screen and (max-width: 1500px) {

    .ui.small.image.custom-size-logo {
        height: 26px;
        width: auto;
    }

    .ui.secondary.pointing.menu .item {
        padding-top: 28px;
        padding-bottom: 28px;
        padding-right: 12px;
        padding-left: 12px;
    }
    
    .ui.secondary.pointing.menu .left.item {
        padding-top: 18px;
        padding-bottom: 20px;
        padding-left: 25px;
    }

    .ui.menu {
        font-size: 0.95rem;
    }
}

@media only screen and (max-width: 1370px) {
    
    .ui.small.image.custom-size-logo {
        height: 26px;
        width: auto;
    }

    .ui.secondary.pointing.menu .item {
        padding-top: 18px;
        padding-bottom: 18px;
        padding-left: 30px;
    }

    .ui.secondary.vertical.pointing.menu .active.item {
        border-left: 4px solid #00B51A !important;
    }

    .ui.sidebar.menu .item {
        text-align: left;
    }
}

.sub-menu-container div .ui.vertical.menu.submenu {
    .item {
        font-weight: 600;
        color: @dinoblue;

        &.black {
            color: @black;
        }
        &.active {
            font-weight: 900;
        }
    }
}