/*******************************
     User Global Variables
*******************************/
.ui.container.overlay-content {
  background-color: #f9fbfc;
}
.ui.container.contentcontainer {
  padding: 3% 7%;
}
.ui.container.overlay-content.scrolling-container {
  padding-left: 0;
  padding-right: 0;
}
iframe.content-iframe {
  overflow: hidden;
}
@media only screen and (max-width: 1370px) {
  .ui.container.overlay-content {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}
DIV.hotspot-content-container-wrapper {
  width: 100%;
}
DIV.hotspot-content-container-wrapper.events-archive {
  min-height: calc(100% + 64px);
}
DIV.hero {
  width: 100%;
  min-height: 4em;
  position: relative;
}
DIV.hero img {
  max-width: 100%;
}
DIV.hero-video {
  padding-bottom: 56.25%;
}
DIV.hero-video iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border: none;
}
DIV.hotspot-headings {
  position: relative;
  z-index: 10;
  transform: translateY(calc(-20px - 2rem - 3em));
  margin: 0 5em -6em;
  max-width: 70%;
  float: left;
  text-align: left;
  font-family: 'Factoria';
}
DIV.hotspot-headings-preheading,
DIV.hotspot-headings-subheading {
  color: white;
  font-size: 2rem;
  line-height: 100%;
  width: auto;
  float: left;
  clear: both;
  padding: 10px;
  min-height: calc(2rem + 20px);
}
DIV.hotspot-headings-preheading:not(:empty),
DIV.hotspot-headings-subheading:not(:empty) {
  background: rgba(75, 74, 74, 0.7);
}
DIV.hotspot-headings-subheading {
  min-height: unset;
}
DIV.hotspot-headings-main {
  float: left;
  clear: both;
  color: white;
  background: #2f3787;
  font-size: 3.143rem;
  line-height: 100%;
  padding: 20px 3rem;
  text-transform: uppercase;
}
DIV.hotspot-main-content-wrapper {
  margin: 0 5em 2em;
  width: calc(100% - 10em);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  text-align: left;
  position: relative;
}
DIV.hotspot-main-content-wrapper.events-archive {
  padding-bottom: 30px;
  align-items: center;
  justify-content: center;
  text-align: center;
}
DIV.hotspot-main-content-wrapper UL.contact {
  margin-top: 1em;
  padding-left: 0;
  list-style-type: none;
}
DIV.hotspot-main-content-wrapper UL.contact LI {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 10px;
}
DIV.hotspot-main-content-wrapper UL.contact LI:before {
  content: " ";
  font-size: 2rem;
  display: block;
  vertical-align: middle;
  text-align: center;
  line-height: 100%;
  padding-left: 0;
  padding-right: 1em;
  min-width: 56px;
  font-family: "Font Awesome 5 Pro";
}
DIV.hotspot-main-content-wrapper UL.contact LI:nth-of-type(1):before {
  content: "\f3c5";
}
DIV.hotspot-main-content-wrapper UL.contact LI:nth-of-type(2):before {
  content: "\f10b";
}
DIV.hotspot-main-content-wrapper UL.contact LI:nth-of-type(3):before {
  content: "\f0e0";
}
DIV.hotspot-main-content-wrapper UL.contact LI::content {
  display: inline-block;
}
DIV.hotspot-main-content-wrapper .hotspot-calendar-button {
  position: absolute;
  top: -6em;
  right: -3em;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
DIV.hotspot-main-content-wrapper .hotspot-calendar-button i {
  color: #FFFFFF;
}
DIV.hotspot-main-content-wrapper .hotspot-full-width {
  flex: 1 0 100%;
  margin: 2em 0;
}
DIV.hotspot-main-content-wrapper .hotspot-full-width.smaller-gap {
  margin: 1em 0;
}
DIV.hotspot-main-content-wrapper .hotspot-full-width.no-top-gap {
  margin-top: 0;
}
DIV.hotspot-main-content-wrapper .hotspot-full-width.smaller-bottom-gap {
  margin-bottom: 1em;
}
DIV.hotspot-main-content-wrapper .hotspot-full-width strong {
  font-size: 1.1rem;
}
DIV.hotspot-main-content-wrapper .hotspot-full-width img {
  max-width: 100%;
}
DIV.hotspot-main-content-wrapper .hotspot-full-width.tour-video {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
}
DIV.hotspot-main-content-wrapper .hotspot-full-width.tour-video iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border: none;
}
DIV.hotspot-main-content-wrapper .hotspot-full-width.events-archive {
  padding: 8px;
  background: rgba(0, 0, 0, 0.8);
}
DIV.hotspot-main-content-wrapper .hotspot-full-width.events-archive h1,
DIV.hotspot-main-content-wrapper .hotspot-full-width.events-archive h2,
DIV.hotspot-main-content-wrapper .hotspot-full-width.events-archive h3,
DIV.hotspot-main-content-wrapper .hotspot-full-width.events-archive h4,
DIV.hotspot-main-content-wrapper .hotspot-full-width.events-archive p {
  color: white;
}
DIV.hotspot-main-content-wrapper .hotspot-full-width.events-archive DIV.registration-form {
  padding-bottom: 30px;
}
DIV.hotspot-main-content-wrapper .hotspot-full-width.flex {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}
DIV.hotspot-main-content-wrapper .hotspot-full-width strong span {
  white-space: nowrap;
}
DIV.hotspot-main-content-wrapper .hotspot-full-width strong span:before,
DIV.hotspot-main-content-wrapper .hotspot-full-width strong span:after {
  content: "'";
  display: inline-block;
}
DIV.hotspot-main-content-wrapper .hotspot-full-width strong span p {
  display: inline-block;
  white-space: normal;
}
DIV.hotspot-main-content-wrapper .hotspot-full-width ul.hotspot-list-50 {
  display: inline-block;
  width: calc(50% - 15px);
  margin: 0;
  padding: 0;
}
DIV.hotspot-main-content-wrapper .hotspot-full-width ul.hotspot-list-50:first-of-type {
  margin-right: 15px;
}
DIV.hotspot-main-content-wrapper .hotspot-full-width .hotspot-fluid {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-around;
}
DIV.hotspot-main-content-wrapper .hotspot-full-width .hotspot-fluid > div:not(:last-of-type) {
  margin-right: 15px;
}
DIV.hotspot-main-content-wrapper .hotspot-full-width .hotspot-fluid.mydino-blocks div {
  width: calc((100% - (2 * 15px)) / 3);
}
DIV.hotspot-main-content-wrapper .hotspot-full-width .hotspot-fluid.mydino-blocks div img {
  max-width: 100%;
}
@media screen and (max-width: 768px) {
  DIV.hotspot-main-content-wrapper .hotspot-full-width .hotspot-fluid.mydino-blocks div {
    width: calc(100% - (2 * 15px));
    margin-right: 15px;
  }
  DIV.hotspot-main-content-wrapper .hotspot-full-width .hotspot-fluid.mydino-blocks div p {
    margin-bottom: 20px;
  }
}
DIV.hotspot-main-content-wrapper .hotspot-full-width .hotspot-fluid.mydino-icons {
  margin: 2em 0 1em;
}
DIV.hotspot-main-content-wrapper .hotspot-full-width .hotspot-fluid.mydino-icons div {
  min-height: 215px;
  width: calc((100% - (4 * 15px)) / 5);
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  text-align: center;
  flex-direction: column;
}
DIV.hotspot-main-content-wrapper .hotspot-full-width .hotspot-fluid.mydino-icons div strong {
  font-family: Factoria, sans-serif;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 2rem;
}
@media screen and (max-width: 768px) {
  DIV.hotspot-main-content-wrapper .hotspot-full-width .hotspot-fluid.mydino-icons div {
    min-width: 45%;
    flex-wrap: wrap;
  }
}
DIV.hotspot-main-content-wrapper .hotspot-half-width,
DIV.hotspot-main-content-wrapper .hotspot-65percent-width,
DIV.hotspot-main-content-wrapper .hotspot-35percent-width {
  flex: 1 0 50%;
  max-width: 50%;
  display: flex;
  padding-bottom: 1em;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-right: 0;
  padding-left: 0.5em;
}
DIV.hotspot-main-content-wrapper .hotspot-half-width:nth-of-type(odd),
DIV.hotspot-main-content-wrapper .hotspot-65percent-width:nth-of-type(odd),
DIV.hotspot-main-content-wrapper .hotspot-35percent-width:nth-of-type(odd) {
  padding-left: 0;
  padding-right: 0.5em;
}
DIV.hotspot-main-content-wrapper .hotspot-half-width.no-padding-right,
DIV.hotspot-main-content-wrapper .hotspot-65percent-width.no-padding-right,
DIV.hotspot-main-content-wrapper .hotspot-35percent-width.no-padding-right {
  padding-right: 0;
}
DIV.hotspot-main-content-wrapper .hotspot-half-width .hotspot-content-image,
DIV.hotspot-main-content-wrapper .hotspot-65percent-width .hotspot-content-image,
DIV.hotspot-main-content-wrapper .hotspot-35percent-width .hotspot-content-image {
  width: 100%;
  padding-bottom: 1em;
}
DIV.hotspot-main-content-wrapper .hotspot-half-width .hotspot-content-image.no-content,
DIV.hotspot-main-content-wrapper .hotspot-65percent-width .hotspot-content-image.no-content,
DIV.hotspot-main-content-wrapper .hotspot-35percent-width .hotspot-content-image.no-content {
  margin: auto;
}
DIV.hotspot-main-content-wrapper .hotspot-half-width.archive-video,
DIV.hotspot-main-content-wrapper .hotspot-65percent-width.archive-video,
DIV.hotspot-main-content-wrapper .hotspot-35percent-width.archive-video {
  padding-bottom: 2.5em;
}
DIV.hotspot-main-content-wrapper .hotspot-half-width.archive-video img,
DIV.hotspot-main-content-wrapper .hotspot-65percent-width.archive-video img,
DIV.hotspot-main-content-wrapper .hotspot-35percent-width.archive-video img,
DIV.hotspot-main-content-wrapper .hotspot-half-width.archive-video h4,
DIV.hotspot-main-content-wrapper .hotspot-65percent-width.archive-video h4,
DIV.hotspot-main-content-wrapper .hotspot-35percent-width.archive-video h4,
DIV.hotspot-main-content-wrapper .hotspot-half-width.archive-video p,
DIV.hotspot-main-content-wrapper .hotspot-65percent-width.archive-video p,
DIV.hotspot-main-content-wrapper .hotspot-35percent-width.archive-video p {
  cursor: pointer;
}
DIV.hotspot-main-content-wrapper .hotspot-65percent-width {
  flex: 1 0 70%;
  max-width: 70%;
}
DIV.hotspot-main-content-wrapper .hotspot-35percent-width {
  flex: 1 0 30%;
  max-width: 30%;
}
DIV.hotspot-main-content-wrapper .accordion-button {
  width: 100%;
  text-align: left;
  background: #FFFFFF;
  color: #2f3787;
  border: 1px solid #2f3787;
  padding: 5px 10px;
  position: relative;
}
DIV.hotspot-main-content-wrapper .accordion-button:after {
  display: block;
  position: absolute;
  right: 10px;
  top: 25%;
  color: #2f3787;
  height: 25px;
  width: 25px;
  line-height: 100%;
  content: '\f078';
  font-family: "Font Awesome 5 Pro";
  transition: transform 0.3s ease-in-out !important;
  transform: rotate(-180deg);
}
DIV.hotspot-main-content-wrapper .accordion-button.collapsed:after {
  transform: rotate(0deg);
}
DIV.hotspot-main-content-wrapper .downloads-list {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}
DIV.hotspot-main-content-wrapper .downloads-list a {
  flex: 0 0 auto;
  margin: 1em 0;
}
DIV.hotspot-main-content-wrapper .hotspot-gallery {
  max-width: 50vw;
}
DIV.hotspot-main-content-wrapper .hotspot-gallery h2 {
  margin: 0 0 1em;
}
DIV.hotspot-main-content-wrapper .hotspot-gallery .gallery-reel-item {
  min-height: 240px;
}
DIV.hotspot-main-content-wrapper .hotspot-gallery .swiper:not(.swiper-thumbs) .swiper-wrapper .swiper-slide img {
  max-width: 50vw;
  max-height: 800px;
  object-fit: contain;
}
DIV.hotspot-main-content-wrapper .hotspot-gallery .swiper-button-prev {
  transform: translateX(-30px);
}
DIV.hotspot-main-content-wrapper .hotspot-gallery p {
  margin-top: 1em;
  max-width: 100%;
  overflow: hidden;
}
DIV.hotspot-main-content-wrapper .hotspot-gallery .gallery-reel-item {
  padding: 1em;
  border: 1px solid #2f3787;
  margin: 1em;
}
DIV.hotspot-main-content-wrapper .hotspot-gallery .gallery-reel-item iframe,
DIV.hotspot-main-content-wrapper .hotspot-gallery .gallery-reel-item img {
  max-width: 100%;
  height: auto;
}
DIV.hotspot-main-content-wrapper .hotspot-gallery .gallery-button {
  position: absolute;
  font-family: "Font Awesome 5 Pro";
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FFFFFF;
  background-color: #2f3787;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  left: 0;
  top: calc(50% - 15px);
  z-index: 6000;
  cursor: pointer;
}
DIV.hotspot-main-content-wrapper .hotspot-gallery .gallery-button-next {
  left: auto;
  right: 0;
}
DIV.hotspot-main-content-wrapper .hotspot-gallery .gallery-video-view {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
}
DIV.hotspot-main-content-wrapper .hotspot-gallery .gallery-video-view iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
DIV.hotspot-main-content-wrapper .hotspot-person {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-top: 2px solid #2f3787;
  border-bottom: 2px solid #2f3787;
  padding: 2em 0;
}
DIV.hotspot-main-content-wrapper .hotspot-person-image {
  width: auto;
  max-width: 100px;
  height: 100px;
  border-radius: 50px;
  border: 2px solid #2f3787;
  margin-right: 2em;
  padding: 5px;
}
DIV.hotspot-main-content-wrapper .hotspot-person a {
  color: #000000;
}
DIV.hotspot-main-content-wrapper .hotspot-person a:hover {
  color: #2f3787;
  text-decoration: none;
}
DIV.hotspot-main-content-wrapper .hotspot-archive-main-video {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
}
DIV.hotspot-main-content-wrapper .hotspot-archive-main-video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
DIV.hotspot-main-content-wrapper .hotspot-archive-video {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
  margin-bottom: 1.5em;
}
DIV.hotspot-main-content-wrapper .hotspot-archive-video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
DIV.hotspot-events-wrapper {
  margin: 1em 2em 2em;
  width: calc(100% - 4em);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  text-align: left;
  position: relative;
  gap: 25px;
  align-items: flex-start;
  padding-bottom: 3em;
}
DIV.hotspot-events-wrapper .events-stream {
  flex: 1 0 67.7%;
  height: auto;
}
DIV.hotspot-events-wrapper .events-stream .stream {
  position: relative;
  padding-bottom: 56.25%;
  width: 100%;
}
DIV.hotspot-events-wrapper .events-stream .stream iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border: none;
}
DIV.hotspot-events-wrapper .events-stream .polllink {
  margin-top: 25px;
}
DIV.hotspot-events-wrapper .events-chat {
  flex: 1 0 calc(33.3% - 40px);
  height: 552px;
  min-width: 300px;
}
DIV.hotspot-events-wrapper .events-chat div {
  width: 100%;
  height: 100%;
}
DIV.hotspot-events-wrapper .events-chat div iframe {
  width: 100%;
  height: 100%;
  border: none;
  border-bottom: thin solid lightgrey;
}
DIV.hotspot-events-wrapper .events-schedule {
  flex: 1 1 66.7%;
}
@media screen and (max-width: 1180px) {
  .events-stream,
  .events-chat,
  .events-schedule {
    flex: 1 0 100% !important;
    width: 100%;
    max-width: 100%;
  }
}
@media screen and (max-width: 760px) {
  DIV.hotspot-headings {
    transform: translateY(-11.5vw);
    margin: 0 3vw -10vw;
  }
  DIV.hotspot-headings-preheading,
  DIV.hotspot-headings-subheading {
    padding: 1vw;
    font-size: 3vw;
    min-height: 7vw;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  DIV.hotspot-headings-main {
    font-size: 5vw;
    min-height: 9vw;
    padding: 2vw 3vw;
  }
  DIV.hotspot-main-content-wrapper {
    margin: 0 4vw 2em;
    width: calc(100% - 8vw);
  }
  DIV.hotspot-main-content-wrapper .hotspot-calendar-button {
    right: -3vw;
    top: -3em;
    font-size: 0.75rem !important;
  }
  DIV.hotspot-main-content-wrapper DIV.hotspot-half-width,
  DIV.hotspot-main-content-wrapper .hotspot-65percent-width,
  DIV.hotspot-main-content-wrapper .hotspot-35percent-width {
    flex: 1 0 100% !important;
    width: 100%;
    max-width: 100%;
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  DIV.hotspot-main-content-wrapper .hotspot-gallery {
    max-width: 100%;
  }
}
