@import '../semantic-ui/site/globals/site.variables';

.hubspot-form {
	LABEL {
		padding: 10px 10px 0 0;
		margin: 0;
	}

	SELECT.hs-input, INPUT.hs-input, TEXTAREA.hs-input {
		background: @white;
		padding: 5px;
		margin: 0;
		width: 100% !important;
		border: 1px solid @dinoblue;
	}

	DIV.hs-recaptcha {
		margin: 1em 0;
	}

	INPUT.hs-button {
		background: @dinoblue;
		border-radius: 0;
		color: @white;
		border: none;
		padding: 10px 15px;
	}
}