/*******************************
     User Global Variables
*******************************/
.hubspot-form LABEL {
  padding: 10px 10px 0 0;
  margin: 0;
}
.hubspot-form SELECT.hs-input,
.hubspot-form INPUT.hs-input,
.hubspot-form TEXTAREA.hs-input {
  background: #FFFFFF;
  padding: 5px;
  margin: 0;
  width: 100% !important;
  border: 1px solid #2f3787;
}
.hubspot-form DIV.hs-recaptcha {
  margin: 1em 0;
}
.hubspot-form INPUT.hs-button {
  background: #2f3787;
  border-radius: 0;
  color: #FFFFFF;
  border: none;
  padding: 10px 15px;
}
