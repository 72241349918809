/*******************************
     User Global Variables
*******************************/
#save-container {
  font-size: large;
  color: coral;
  text-shadow: 1px 1px 3px cornflowerblue;
  position: fixed;
  display: block;
  top: 95%;
  left: 95%;
  right: 2%;
  bottom: 0;
  width: 3%;
  height: 5%;
  z-index: 999;
  pointer-events: none;
}
#overlay-content-ui {
  position: fixed;
  display: block;
  top: 13%;
  left: 15%;
  right: 15%;
  bottom: 1%;
  width: 60%;
  height: 90%;
  z-index: 1005;
  pointer-events: auto;
  max-width: 1370px !important;
}
#overlay-content-ui-video {
  position: fixed;
  display: block;
  top: 11%;
  left: 14%;
  right: 14%;
  bottom: 3%;
  width: 72%;
  height: 86%;
  z-index: 1005;
  pointer-events: auto;
}
.ui.container.scrolling-container {
  overflow: auto;
  overscroll-behavior: contain;
  max-height: 85vh;
}
@media only screen and (max-width: 1370px) {
  #overlay-content-ui {
    position: fixed;
    display: block;
    top: 64px;
    left: 0%;
    right: 0%;
    bottom: 0%;
    width: 100% !important;
    height: calc(100% - 64px);
    z-index: 1005;
    pointer-events: auto;
  }
  #overlay-content-ui-video {
    position: fixed;
    display: block;
    top: 64px;
    left: 0%;
    right: 0%;
    bottom: 0%;
    width: 100% !important;
    height: calc(100% - 64px);
    z-index: 1005;
    pointer-events: auto;
  }
  .ui.container.scrolling-container {
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 93vh;
  }
}
