/*******************************
     User Global Variables
*******************************/
.overlay-ui {
  position: fixed;
  display: block;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100% !important;
  height: 100%;
  z-index: 998;
  pointer-events: none;
}
.main-menu-container {
  z-index: 1000 !important;
}
#about-rx-mill-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(47, 51, 133, 0.71);
  opacity: 0.99;
  z-index: 999;
}
#debug-container {
  font-size: x-large;
  color: coral;
  text-shadow: 1px 1px 3px cornflowerblue;
  position: fixed;
  display: block;
  top: 95%;
  left: 2%;
  right: 95%;
  bottom: 0;
  width: 3%;
  height: 5%;
  z-index: 999;
  pointer-events: none;
}
.hubspot-form-container {
  padding: 10% 15%;
  opacity: 80%;
  width: 65%;
  height: 80%;
}
#book-a-demo-container {
  position: fixed;
  display: block;
  left: 1%;
  right: 84%;
  top: 93%;
  bottom: 3%;
  width: 15%;
  height: 4%;
}
#book-a-demo-container > button {
  border-radius: 0px;
}
@media only screen and (max-width: 1370px) {
  .overlay-ui.hotspot-content-container {
    margin-left: 0% !important;
    margin-right: 0% !important;
  }
  .ui.container.main-menu-container {
    width: 100% !important;
    margin-left: 0 !important;
  }
  #book-a-demo-container {
    display: none;
  }
}
