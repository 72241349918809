@import '../semantic-ui/site/globals/site.variables';

.ui.container.overlay-content {
    background-color: #f9fbfc;
}

.ui.container.contentcontainer {
    padding: 3% 7%;

}

.ui.container.overlay-content.scrolling-container {
    padding-left: 0;
    padding-right: 0;
}

iframe.content-iframe {
    overflow: hidden;
}

@media only screen and (max-width: 1370px) {

    .ui.container.overlay-content {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }

}

DIV.hotspot-content-container-wrapper {
    width: 100%;

    &.events-archive {
        min-height: calc(100% + 64px);
    }
}

DIV.hero {
    width: 100%;
    min-height: 4em;
    position: relative;

    img {
        max-width: 100%;
    }

    &-video {
        padding-bottom: 56.25%;

        & iframe {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            border: none;
        }
    }
}

DIV.hotspot-headings {
    position: relative;
    z-index: 10;
    transform: translateY(calc(-20px - 2rem - 3em));
    margin: 0 5em -6em;
    max-width: 70%;
    float: left;
    text-align: left;
    font-family: @fontName;

    &-preheading,
    &-subheading {
        color: white;

        &:not(:empty) {
            background: @lightgrey;
        }

        font-size: 2rem;
        line-height: 100%;
        width: auto;
        float: left;
        clear: both;
        padding: 10px;
        min-height: calc(2rem + 20px);
    }

    &-subheading {
        min-height: unset;
    }

    &-main {
        float: left;
        clear: both;
        color: white;
        background: @dinoblue;
        font-size: 3.143rem;
        line-height: 100%;
        padding: 20px 3rem;
        text-transform: uppercase;
    }
}

DIV.hotspot-main-content-wrapper {
    margin: 0 5em 2em;
    width: calc(100% - 10em);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    text-align: left;
    position: relative;

    &.events-archive {
        padding-bottom: 30px;
        align-items: center;
        justify-content: center;
        text-align: center;
    }

    UL.contact {
        margin-top: 1em;
        padding-left: 0;

        LI {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin-bottom: 10px;

            &:before {
                content: " ";
                font-size: 2rem;
                display: block;
                vertical-align: middle;
                text-align: center;
                line-height: 100%;
                padding-left: 0;
                padding-right: 1em;
                min-width: 56px;
                font-family: "Font Awesome 5 Pro";
            }

            &:nth-of-type(1):before {
                content: "\f3c5";
            }

            &:nth-of-type(2):before {
                content: "\f10b";
            }

            &:nth-of-type(3):before {
                content: "\f0e0";
            }

            &::content {
                display: inline-block;
            }
        }

        list-style-type: none;
    }

    .hotspot-calendar-button {
        position: absolute;
        top: -6em;
        right: -3em;
        display: flex;
        justify-content: space-between;
        align-items: center;

        i {
            color: @white;
        }
    }

    .hotspot-full-width {
        flex: 1 0 100%;
        margin: 2em 0;

        &.smaller-gap {
            margin: 1em 0;
        }

        &.no-top-gap {
            margin-top: 0;
        }

        &.smaller-bottom-gap {
            margin-bottom: 1em;
        }

        strong {
            font-size: 1.1rem;
        }

        img {
            max-width: 100%;
        }

        &.tour-video {
            position: relative;
            width: 100%;
            padding-bottom: 56.25%;

            & iframe {
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                border: none;
            }
        }

        &.events-archive {

            h1,
            h2,
            h3,
            h4,
            p {
                color: white;
            }

            padding: 8px;
            background: rgba(0, 0, 0, 0.8);

            DIV.registration-form {
                padding-bottom: 30px;
            }
        }

        &.flex {
            display: flex;
            flex-wrap: wrap;
            gap: 15px;
        }

        strong span {
            white-space: nowrap;

            &:before,
            &:after {
                content: "'";
                display: inline-block;
            }

            p {
                display: inline-block;
                white-space: normal;
            }
        }

        ul.hotspot-list-50 {
            display: inline-block;
            width: calc(50% - 15px);
            margin: 0;
            padding: 0;

            &:first-of-type {
                margin-right: 15px;
            }
        }

        .hotspot-fluid {
            display: flex;
            flex-wrap: wrap;
            align-items: flex-start;
            justify-content: space-around;

            &>div:not(:last-of-type) {
                margin-right: 15px;
            }

            &.mydino-blocks {
                div {
                    width: calc((100% - (2 * 15px)) / 3);

                    img {
                        max-width: 100%;
                    }

                    @media screen and (max-width: 768px) {
                        width: calc(100% - (2 * 15px));
                        margin-right: 15px;

                        p {
                            margin-bottom: 20px;
                        }
                    }
                }
            }

            &.mydino-icons {
                margin: 2em 0 1em;

                div {
                    min-height: 215px;
                    width: calc((100% - (4 * 15px)) / 5);
                    display: flex;
                    align-items: center;
                    justify-content: space-evenly;
                    text-align: center;
                    flex-direction: column;

                    strong {
                        font-family: Factoria, sans-serif;
                        font-size: 1.5rem;
                        font-weight: 600;
                        line-height: 2rem;
                    }

                    @media screen and (max-width: 768px) {
                        min-width: 45%;
                        flex-wrap: wrap;
                    }
                }
            }
        }
    }

    .hotspot-half-width,
    .hotspot-65percent-width,
    .hotspot-35percent-width {
        flex: 1 0 50%;
        max-width: 50%;
        display: flex;
        padding-bottom: 1em;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        padding-right: 0;
        padding-left: 0.5em;

        &:nth-of-type(odd) {
            padding-left: 0;
            padding-right: 0.5em;
        }

        &.no-padding-right {
            padding-right: 0;
        }

        .hotspot-content-image {
            width: 100%;
            padding-bottom: 1em;

            &.no-content {
                margin: auto;
            }
        }

        &.archive-video {
            padding-bottom: 2.5em;

            img,
            h4,
            p {
                cursor: pointer;
            }
        }
    }

    .hotspot-65percent-width {
        flex: 1 0 70%;
        max-width: 70%;
    }

    .hotspot-35percent-width {
        flex: 1 0 30%;
        max-width: 30%;
    }

    .accordion-button {
        width: 100%;
        text-align: left;
        background: @white;
        color: @dinoblue;
        border: 1px solid @dinoblue;
        padding: 5px 10px;
        position: relative;

        &:after {
            display: block;
            position: absolute;
            right: 10px;
            top: 25%;
            color: @dinoblue;
            height: 25px;
            width: 25px;
            line-height: 100%;
            content: '\f078';
            font-family: "Font Awesome 5 Pro";
            transition: transform 0.3s ease-in-out !important;
            transform: rotate(-180deg);
        }

        &.collapsed:after {
            transform: rotate(0deg);
        }
    }

    .downloads-list {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;

        a {
            flex: 0 0 auto;
            margin: 1em 0;
        }
    }

    .hotspot-gallery {
        max-width: 50vw;

        h2 {
            margin: 0 0 1em;
        }

        .gallery-reel-item {
            min-height: 240px;
        }

        .swiper:not(.swiper-thumbs) .swiper-wrapper .swiper-slide img {
            max-width: 50vw;
            max-height: 800px;
            object-fit: contain;
        }

        .swiper-button-prev {
            transform: translateX(-30px);
        }

        p {
            margin-top: 1em;
            max-width: 100%;
            overflow: hidden;
        }

        .gallery-reel-item {
            padding: 1em;
            border: 1px solid @dinoblue;
            margin: 1em;

            iframe,
            img {
                max-width: 100%;
                height: auto;
            }
        }

        .gallery-button {
            position: absolute;
            font-family: "Font Awesome 5 Pro";
            display: flex;
            align-items: center;
            justify-content: center;
            color: @white;
            background-color: @dinoblue;
            border-radius: 50%;
            width: 30px;
            height: 30px;
            left: 0;
            top: calc(50% - 15px);
            z-index: 6000;
            cursor: pointer;

            &-next {
                left: auto;
                right: 0;
            }
        }

        .gallery-video-view {
            position: relative;
            width: 100%;
            padding-bottom: 56.25%;

            iframe {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
        }
    }

    .hotspot-person {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        border-top: 2px solid @dinoblue;
        border-bottom: 2px solid @dinoblue;
        padding: 2em 0;

        &-image {
            width: auto;
            max-width: 100px;
            height: 100px;
            border-radius: 50px;
            border: 2px solid @dinoblue;
            margin-right: 2em;
            padding: 5px;
        }

        a {
            color: @black;

            &:hover {
                color: @dinoblue;
                text-decoration: none;
            }
        }
    }

    .hotspot-archive-main-video {
        position: relative;
        width: 100%;
        padding-bottom: 56.25%;

        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }

    .hotspot-archive-video {
        position: relative;
        width: 100%;
        padding-bottom: 56.25%;
        margin-bottom: 1.5em;

        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
}


DIV.hotspot-events-wrapper {
    margin: 1em 2em 2em;
    width: calc(100% - 4em);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    text-align: left;
    position: relative;
    gap: 25px;
    align-items: flex-start;
    padding-bottom: 3em;

    .events-stream {
        flex: 1 0 67.7%;
        height: auto;

        .stream {
            position: relative;
            padding-bottom: 56.25%;
            width: 100%;

            iframe {
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                border: none;
            }
        }

        .polllink {
            margin-top: 25px;
        }
    }

    .events-chat {
        flex: 1 0 calc(33.3% - 40px);
        height: 552px;

        min-width: 300px;

        div {
            width: 100%;
            height: 100%;

            iframe {
                width: 100%;
                height: 100%;
                border: none;
                border-bottom: thin solid lightgrey;
            }
        }
    }

    .events-schedule {
        flex: 1 1 66.7%;
    }
}

@media screen and (max-width: 1180px) {

    .events-stream,
    .events-chat,
    .events-schedule {
        flex: 1 0 100% !important;
        width: 100%;
        max-width: 100%;
    }
}

@media screen and (max-width: @narrow) {
    DIV.hotspot-headings {
        transform: translateY(-11.5vw);
        margin: 0 3vw -10vw;

        &-preheading,
        &-subheading {
            padding: 1vw;
            font-size: 3vw;
            min-height: 7vw;
            text-align: left;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &-main {
            font-size: 5vw;
            min-height: 9vw;
            padding: 2vw 3vw;
        }
    }

    DIV.hotspot-main-content-wrapper {
        margin: 0 4vw 2em;
        width: calc(100% - 8vw);

        .hotspot-calendar-button {
            right: -3vw;
            top: -3em;
            font-size: 0.75rem !important;
        }

        DIV.hotspot-half-width,
        .hotspot-65percent-width,
        .hotspot-35percent-width {
            flex: 1 0 100% !important;
            width: 100%;
            max-width: 100%;
            padding-right: 0 !important;
            padding-left: 0 !important;

        }

        .hotspot-gallery {
            max-width: 100%;
        }
    }
}