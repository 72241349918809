/*******************************
     User Global Variables
*******************************/
.ui.labeled.icon.button.viewpoint-button {
  color: #FFFFFF;
  padding-top: 18px;
  padding-bottom: 18px;
  font-size: 15px;
  border-radius: 0;
  opacity: 0.9;
}
.ui.button.viewpoint-button {
  color: #000000;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 15px;
  border-radius: 0;
  opacity: 0.9;
}
.ui.icon.button.hotspot-rectangular-button {
  border-radius: 0;
}
.ui.icon.button.hotspot-rectangular-button i.icon {
  display: inline-block;
}
.ui.icon.button.viewpoint-rectangular-button {
  border-radius: 0;
}
.ui.icon.button.hotspot-button {
  height: 50px;
  width: 50px;
}
i.icon.icon360 {
  height: 100% !important;
  width: 100% !important;
  background: transparent url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 47 43.7' style='enable-background:new 0 0 47 43.7;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23FFFFFF;stroke:%23FFFFFF;stroke-width:1;%7D%0A%3C/style%3E%3Cg transform='translate(0.15 0.15)'%3E%3Cpath class='st0' d='M23.1,41.5c-2.9,0-5.5-4.1-6.7-10.8c-0.1-0.4,0.2-0.7,0.5-0.8c0.4-0.1,0.7,0.2,0.8,0.5 c1.1,5.8,3.3,9.8,5.4,9.8s4.4-3.9,5.4-9.8c0.1-0.4,0.4-0.6,0.8-0.5c0.3,0.1,0.6,0.4,0.5,0.8C28.5,37.4,26,41.5,23.1,41.5'/%3E%3Cpath class='st0' d='M31.9,11.5c-0.3-0.2-0.7-0.1-0.9,0.2l-1.2,1.2c-0.4-2.7-1.2-5.3-2.4-7.8 c-1.2-2.2-2.7-3.4-4.3-3.4S20,2.9,18.8,5.1c-1.3,2.6-2.2,5.3-2.6,8.2c-0.1,0.4,0.2,0.7,0.5,0.7c0.3,0.1,0.7-0.2,0.7-0.5 C18.6,7.2,20.8,3,23.1,3c2.2,0,4.4,4,5.5,9.9l-1.8-1.2c-0.3-0.2-0.7-0.2-0.9,0.1c-0.2,0.3-0.2,0.7,0.1,0.9c0,0,0,0,0,0l2.9,2l0,0 l0,0c0.3,0.2,0.7,0.1,0.9-0.2l2.1-2.2C32.2,12.2,32.2,11.8,31.9,11.5'/%3E%3Cpath class='st0' d='M37.7,16.1c-0.3-0.1-0.7,0.1-0.8,0.5c-0.1,0.3,0.1,0.7,0.4,0.8c3.6,1.1,5.8,2.7,5.8,4.1 c0,1.4-2.1,2.9-5.6,4.1c-4.7,1.3-9.5,2-14.4,1.9c-4.9,0.1-9.7-0.6-14.4-1.9c-3.5-1.1-5.6-2.6-5.6-4.1c0-1.4,2-2.9,5.3-4l-0.5,2 c-0.1,0.3,0,0.7,0.3,0.9c0.3,0.1,0.7,0,0.9-0.3l1.1-3.5c0,0,0,0,0,0v0c0.1-0.3,0-0.7-0.3-0.9l-2.5-2c-0.3-0.1-0.7,0-0.9,0.3 c-0.1,0.3,0,0.5,0.3,0.9l1.4,1.4c-4,1.3-6.5,3.2-6.5,5.2c0,2.1,2.2,3.9,6.5,5.3c4.8,1.4,9.8,2.1,14.8,2c5,0.1,10-0.6,14.8-2 c4.3-1.4,6.5-3.2,6.5-5.3S42,17.5,37.7,16.1'/%3E%3C/g%3E%3C/svg%3E%0A") no-repeat center center;
}
.ui.icon.button {
  color: #FFFFFF;
  border-radius: 50%;
}
.ui.icon.button i.icon {
  transition: transform 0.3s ease-in-out !important;
}
.ui.icon.button i.icon:last-of-type:hover {
  transform: rotate(360deg) !important;
}
.viewpoint-button-group i.icon {
  transition: transform 0.3s ease-in-out !important;
}
.viewpoint-button-group i.icon:last-of-type:hover {
  transform: rotate(360deg) !important;
}
.close-spot-content {
  position: absolute !important;
  right: 5px;
  top: 5px;
  z-index: 9001;
}
.close-spot-content i {
  background: #2f3787;
  padding: 10px;
  border-radius: 50%;
  width: 50px !important;
  height: 50px !important;
  display: block;
}
.close-spot-content button {
  margin-top: 4em !important;
}
.events-overlay .close-spot-content {
  position: absolute !important;
  right: 2px;
  top: 2px;
  z-index: 9001;
}
.events-overlay .close-spot-content i {
  background: #2f3787;
  padding: 7px;
  border-radius: 50%;
  width: 25px !important;
  height: 25px !important;
  display: block;
  margin-right: 0 !important;
  font-size: 1em;
}
.close-spot-content > .icon:first-child {
  color: white;
}
.close-spot.content > .icon:first-child :hover {
  color: rgba(0, 0, 0, 0.2);
}
.close-spot-content:hover {
  position: absolute !important;
  cursor: pointer;
}
@keyframes open-hotspot-overlay {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.13;
  }
}
.hotspot-button-group {
  align-content: center;
  justify-content: center;
  position: relative;
}
.hotspot-button-group:hover > .button.button.ui.button.hotspot-label {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.8s ease-in-out !important;
}
.button.ui.button.hotspot-label {
  background-color: #2f3385;
  border-radius: 0;
  visibility: hidden;
  margin-top: 0.5em;
  opacity: 0;
}
.content-hotspot-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #000000;
  mix-blend-mode: normal;
  opacity: 0.13;
  animation-name: open-hotspot-overlay;
  animation-duration: 0.75s;
}
#overlay-content-ui.events-overlay {
  background-color: white;
  width: calc(100% - 2em);
  left: 0;
  right: 0;
}
#overlay-content-ui.events-overlay .close-spot-content {
  right: 5px;
  top: 5px;
}
#overlay-content-ui.events-overlay .hotspot-draggable {
  margin: 2em 0 0 0;
}
#overlay-content-ui.events-overlay .hotspot-draggable.minimized {
  width: 10vw;
  left: auto;
}
#overlay-content-ui.events-overlay .hotspot-content-container-wrapper {
  height: calc(100% - 4em);
}
#overlay-content-ui.events-overlay .event-hotspot-content {
  height: 100%;
  width: 100%;
  background-color: #f9fbfc;
  max-height: 45vh;
  overflow: hidden;
  min-height: 10vh;
}
#overlay-content-ui.events-overlay .event-hotspot-content .event-hotspot-video {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
#overlay-content-ui.events-overlay .event-hotspot-content .event-hotspot-video iframe {
  max-height: 45vh;
  border: none;
}
#overlay-content-ui.events-overlay .event-hotspot-stream {
  height: 54%;
}
#overlay-content-ui.events-overlay .event-hotspot-stream .event-hotspot-content {
  background-color: #fff;
  padding: 0em;
}
#overlay-content-ui.events-overlay .event-hotspot-stream iframe {
  height: 100%;
  width: auto;
  border: none;
}
#overlay-content-ui.events-overlay .event-hotspot-stream.maximized {
  height: calc(100% - 2em);
  max-width: 100%;
}
#overlay-content-ui.events-overlay .event-hotspot-stream.maximized .event-hotspot-content {
  max-height: 100%;
}
#overlay-content-ui.events-overlay .event-hotspot-stream.maximized .event-hotspot-content .event-hotspot-video IFRAME {
  max-height: 100%;
}
#overlay-content-ui.events-overlay .event-hotspot-schedule {
  height: 41%;
}
#overlay-content-ui.events-overlay .event-hotspot-schedule .event-hotspot-content {
  padding: 2em 3em;
}
#overlay-content-ui.events-overlay .event-hotspot-schedule .event-hotspot-content h3 {
  font-size: 1.285rem;
  line-height: 1.5rem;
}
#overlay-content-ui.events-overlay .event-hotspot-chat {
  height: 54%;
}
#overlay-content-ui.events-overlay .event-hotspot-chat iframe {
  width: 100%;
  height: 100%;
  border: none;
}
#overlay-content-ui.events-overlay .event-hotspot-chat IFRAME HTML {
  background: none !important;
}
#overlay-content-ui.events-overlay .event-hotspot-schedule .event-hotspot-content {
  overflow-y: auto;
}
#overlay-content-ui.events-overlay .hotspot-minimized-container {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}
#overlay-content-ui.events-overlay .hotspot-minimized-container .hotspot-minimized {
  margin: 0 1em;
  /** The stream embed page is designed (at their end) so that content cannot be smaller than these values. */
}
#overlay-content-ui.events-overlay .hotspot-minimized-container .hotspot-minimized .floatbox-title {
  padding: 1em;
}
#overlay-content-ui.events-overlay .hotspot-minimized-container .hotspot-minimized .floatbox-title-text {
  flex: 1 0 calc(100% - 40px);
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
#overlay-content-ui.events-overlay .hotspot-minimized-container .hotspot-minimized .event-hotspot-content,
#overlay-content-ui.events-overlay .hotspot-minimized-container .hotspot-minimized .event-stream {
  min-width: 465px;
  min-height: 355px;
  position: relative;
}
#overlay-content-ui.events-overlay .hotspot-minimized-container .hotspot-minimized .event-hotspot-content iframe,
#overlay-content-ui.events-overlay .hotspot-minimized-container .hotspot-minimized .event-stream iframe {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  height: 100%;
  margin: 0 auto;
}
#overlay-content-ui.events-overlay .event-info-preview {
  margin: 6em auto;
  text-align: center;
}
#overlay-content-ui.events-overlay .event-info-preview h1 {
  margin-bottom: 1em;
}
#overlay-content-ui.events-overlay .event-info-preview h2 {
  margin-top: 1em;
  margin-bottom: 1em;
}
#overlay-content-ui.events-overlay .event-info-preview .event-countdown div span {
  font-variant-numeric: tabular-nums;
  white-space: nowrap;
  margin-right: 0.5em;
}
#overlay-content-ui.events-overlay .event-info-preview .event-countdown div span:first-of-type {
  margin-left: 4em;
  color: yellow;
}
#overlay-content-ui.events-overlay .event-info-preview .event-countdown div span:last-of-type {
  margin-right: 0;
}
#overlay-content-ui.events-register-overlay {
  width: 600px;
  background: #000 url(DINOHub-banner.jpg) no-repeat;
  background-size: cover;
}
#overlay-content-ui.events-register-overlay .event-registration-form {
  margin: 6em auto;
}
#overlay-content-ui.events-register-overlay .event-registration-form h1,
#overlay-content-ui.events-register-overlay .event-registration-form h2 {
  color: white;
  text-shadow: #000 2px 2px;
  line-height: 100%;
  text-align: center;
}
#overlay-content-ui.events-register-overlay .event-registration-form .actions {
  text-align: center;
}
@media screen and (max-width: 760px) {
  #overlay-content-ui.events-overlay .hotspot-main-content-wrapper {
    overflow: auto;
  }
  #overlay-content-ui.events-register-overlay .hotspot-main-content-wrapper {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    padding: 20px;
  }
}
.fal,
.far {
  font-family: "Font Awesome 5 Pro" !important;
}
@media only screen and (max-width: 1370px) {
  .ui.labeled.icon.button.viewpoint-button {
    padding-top: 18px;
    padding-bottom: 18px;
    font-size: 13px;
  }
  .ui.blue.button.viewpoint-button {
    font-size: 13px;
  }
}
.ui.button.close-content {
  background-color: #2f3787;
  color: #FFFFFF;
  font-size: 1.2rem;
  border-radius: 0;
}
A.blue-link {
  background-color: #2f3787;
  color: #FFFFFF;
  font-size: 1.2rem;
  font-weight: bold;
  padding: 10px 15px;
  position: relative;
  line-height: 100%;
  text-decoration: none;
}
A.blue-link:hover {
  text-decoration: none;
  color: #FFFFFF;
}
A.blue-link i {
  font-size: 1.5rem;
  line-height: 100%;
}
@media screen and (max-width: 760px) {
  A.blue-link {
    display: flex;
  }
}
