.catch-pointer-events {
	pointer-events: auto;
	touch-action: auto;
}

.avoid-pointer-events {
	pointer-events: none;
	touch-action: none;
}

.App {
	text-align: center;
	position: relative;
	max-height: 100vh;
	min-height: 100vh;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

a-scene {
	height: 100vh;
	width: 100vw;
}

.Countdown {
	z-index: 9001;
	width: 100vw;
	margin: 0;
	padding: 0;
	height: 100vh;
	position: absolute;
	top: 0;
	left: 0;
	background: #000 url(DINOHub-banner.jpg) no-repeat;
	background-size: cover;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}

.Countdown-Text DIV, H1.Countdown-Text, H2.Countdown-Text {
	color: white;
	text-shadow: #000 2px 2px;
	line-height: 100%;
}
.Countdown-Text DIV {
	background: rgba(0, 0, 0, 0.8);
	font-size: 4rem;
	font-variant-numeric: tabular-nums;
	padding: 2vw;
	margin-top: 1em;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
}
.Countdown-Text DIV SPAN {
	white-space: nowrap;
}
@media screen and (min-width: 1200px) {
	.Countdown-Text DIV SPAN:not(:last-of-type):after {
		content: " ";
		white-space: pre;
	}
}

.Countdown-Text::selection, H1.Countdown-Text::selection, H2.Countdown-Text::selection, .Countdown-Text DIV::selection {
	text-shadow: none;
}

.registration-form {
	margin-top: 2em;
	font-family: 'Factoria';
}
.registration form UL, .registration-form LI {
	list-style-type: none;
	margin-left: 0;
	padding-left: 0;
}
.registration-form .hubspot-form LABEL {
	color: white;
	background: rgba(0, 0, 0, 0.6);
	padding: 10px;
	width: 100%;
}
.registration-form .hubspot-form .hs-input {
	min-width: 15vw;
	margin-bottom: 1em;
}
.registration-form .hs-button {
	margin-top: 1em;
}

.global-float-content {
	position: absolute;
	top: calc(75px + 1em);
	right: 1em;
	width: 33vw;
	height: 13.5vw;
	min-width: 465px;
	min-height: 355px;
	z-index: 9500;
}

@media screen and (max-width: 760px) {
	.global-float-content {
		min-width: 90vw;
		min-height: 50.625vw;
	}
	.global-float-content .drag-handle {
		display: none;
	}
}

i.icons.global-float-content-controls {
	position: absolute;
	top: 1em;
	right: 1em;
	color: white;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 9001;
}
i.icons.global-float-content-controls i:first-child {
	position: relative;
	width: unset;
	height: unset;
	display: inline-block;
}
i.icons.global-float-content-controls .icon {
	cursor: pointer;
	position: relative;
	top: 0;
	left: auto;
	margin: 0 0 0 5px;
	transform: none;
}
i.icons.global-float-content-controls .icon.rotate270 {
	transform: rotate(270deg);
}

.global-float-content .close-spot-content i {
	background-color: transparent;
}

.global-float-content DIV.global-float-content-container {
	height: 100%;
	width: 100%;
	padding-bottom: 56.25%;
}
.global-float-content DIV.global-float-content-container IFRAME {
	position: absolute;
	top: 0;
	left: 0;
	max-width: 100%;
	width: 100% !important;
	height: 100%;
	max-height: 100%;
	border: none;
}

.feedback-link {
	position: absolute !important;
	bottom: 10px;
	left: 78px;
	z-index: 1100;
	display: block !important;
}

@media only screen and (max-width: 379px) {
	-feedback-link {
		height: 45px;
		padding-top: 15px !important;
		text-transform: capitalize;
	}
	.feedback-link > span, .feedback-link i.icon {
		display: none;
	}
}

#hubspot-messages-iframe-container {
	z-index: 2147483630 !important; /* must be smaller han hubspot chat message window has */
}
