@import '../semantic-ui/site/globals/site.variables';

.numberCircle {
    border-radius: 50%;
    width: 30px;
    height: 30px;
    padding: 6px;
    display: inline-block;
    margin-right: 0.5em;
    border: 2px solid @blue;
    color: @blue;
    text-align: center;
    background-color: white;
}

.active .numberCircle {
    background-color: @blue;
    color: white;
    font-weight: 400;
}

.ui.menu {
    font-weight: 400;
}

.ui.menu .item a {
    padding-left: 3em;
}

.ui.menu .item {
    font-weight: 400;
    display: inline;
}

@keyframes blink {
    50% {
        opacity: 0;
    }
}
.events-live-badge {
    display: block;
    color: @red;
    opacity: 1;
    align-self: flex-start;
    font-size: 0.6rem;
    padding-left: 5px;
    text-transform: uppercase;
    animation: blink 2s linear infinite;
}

.ui.secondary.menu .item.custom-menu-item:hover {
    color: @blue;
}

.ui.secondary.menu .item {
    color: @blue !important;
}

.ui.secondary.menu .active.item {
    border-color: @blue !important;
    border-width: 2px;
    color: @blue;
    font-weight: 1000;
    background: darken(#fff, 20%);
    border-radius: 0%;
}

.ui.secondary.menu .active.item :hover {
    border-color: @blue !important;
    border-width: 2px;
    color: @blue !important;
}

.ui.small.image.custom-size-logo {
    height: 31px;
    width: auto;
}

.ui.secondary.menu .item {
    padding-top: 30px;
    padding-bottom: 30px;
    color: @blue;
}

.ui.secondary.menu .left.item {
    padding-top: 21px;
    padding-bottom: 23px;
    padding-left: 35px;
}

.ui.vertical.menu.submenu .item:before {
    height: 0;
}

.ui.secondary.catch-pointer-events.menu {
    background-color: fade(#fff, 80%);
}

.ui.menu a.item#join-live-button:hover {
    background-color: darken(@blue, 5%);
}

.ui.menu a.item#talk-to-an-expert:hover {
    background-color: darken(@grey, 5%);
}


@media only screen and (max-width: 1500px) {

    .ui.small.image.custom-size-logo {
        height: 26px;
        width: auto;
    }

    .ui.secondary.pointing.menu .item {
        padding-top: 28px;
        padding-bottom: 28px;
        padding-right: 12px;
        padding-left: 12px;
    }
    
    .ui.secondary.pointing.menu .left.item {
        padding-top: 18px;
        padding-bottom: 20px;
        padding-left: 25px;
    }

    .ui.menu {
        font-size: 0.95rem;
    }
}

@media only screen and (max-width: 1370px) {
    
    .ui.small.image.custom-size-logo {
        height: 26px;
        width: auto;
    }

    .ui.secondary.pointing.menu .item {
        padding-top: 18px;
        padding-bottom: 18px;
        padding-left: 30px;
    }

    .ui.secondary.vertical.pointing.menu .active.item {
        border-left: 4px solid #00B51A !important;
    }

    .ui.sidebar.menu .item {
        text-align: left;
    }
}